<!-- =========================================================================================
  File Name: LayerSidebar.vue
  Description: Layer sidebar component
  ----------------------------------------------------------------------------------------
  Item Name: RIM-Nat / Plateforme 3D collaborative
  Author: Loïc ROYBON
  Author URL:
========================================================================================== -->


<template>
  <vs-sidebar click-not-close position-right parent="body" default-index="1" color="primary" class="add-new-data-sidebar items-no-padding" spacer v-model="isSidebarActiveLocal">
    <div class="mt-6 flex items-center justify-between px-6">
      <h4>{{ $t('layerEdition') }}</h4>
      <feather-icon icon="XIcon" @click.stop="isSidebarActiveLocal = false" class="cursor-pointer"></feather-icon>
    </div>
    <vs-divider class="mb-0"></vs-divider>

    <component :is="scrollbarTag" class="scroll-area--data-list-add-new" :settings="settings" :key="$vs.rtl">

      <div class="flex flex-wrap mb-4 justify-between px-6 mt-0">

        <template v-if="!isNaN(layer_id)">
          <vs-input @keydown.enter="prepareForm" @blur="prepareForm" :label="$t('layer_name')"     v-model="layer_new_name"      name="layer_name"     v-validate="'required'" class="mt-5 w-full"  />
          
          <!-- INFOS DE CONVERSION -->
          <!-- <h5 class="mt-10 w-full">{{ this.$t('conversionInformations') }}</h5> -->
          <div class="px-2 mt-2 w-full">
            <!-- uploaded_filename -->
            <vs-row class="px-2 mt-3 w-full" vs-w="12">
              <vs-col vs-type="flex" vs-justify="default" vs-w="12">{{ this.$t('uploaded_filename') }} :</vs-col>
              <vs-col vs-type="flex" vs-justify="flex-end" vs-align="flex-end" vs-w="12">{{ decodeURIComponent(uploaded_filename) }}</vs-col>
            </vs-row>
            <!-- layer_type -->
            <vs-row class="px-2 mt-3 w-full">
              <vs-col vs-type="flex" vs-justify="default" vs-w="6">{{ this.$t('layer_type') }} :</vs-col>
              <vs-col vs-type="flex" vs-justify="flex-end" vs-align="flex-end" vs-w="6">{{ this.$t(layer_type) }}</vs-col>
            </vs-row>
            <!-- upload_date -->
            <vs-row class="px-2 mt-3 w-full">
              <vs-col vs-type="flex" vs-justify="default" vs-w="6">{{ this.$t('upload_date') }} :</vs-col>
              <vs-col vs-type="flex" vs-justify="flex-end" vs-align="flex-end" vs-w="6">{{ upload_date | veryLongDate }}</vs-col>
            </vs-row>
            <!-- conversion_start_date -->
            <vs-row class="px-2 mt-3 w-full">
              <vs-col vs-type="flex" vs-justify="default" vs-w="6">{{ this.$t('conversion_start_date') }} :</vs-col>
              <vs-col vs-type="flex" vs-justify="flex-end" vs-align="flex-end" vs-w="6">{{ conversion_start_date | veryLongDate }}</vs-col>
            </vs-row>
            <!-- conversion_end_date -->
            <vs-row class="px-2 mt-3 w-full">
              <vs-col vs-type="flex" vs-justify="default" vs-w="6">{{ this.$t('conversion_end_date') }} :</vs-col>
              <vs-col vs-type="flex" vs-justify="flex-end" vs-align="flex-end" vs-w="6">{{ conversion_end_date | veryLongDate }}</vs-col>
            </vs-row>
            <!-- layer_size -->
            <vs-row class="px-2 mt-3 w-full">
              <vs-col vs-type="flex" vs-justify="default" vs-w="6">{{ this.$t('layer_size') }} :</vs-col>
              <vs-col v-if="!isNaN(layer_size)" vs-type="flex" vs-justify="flex-end" vs-align="flex-end" vs-w="6">{{ parseFloat(layer_size) | prettyBytes }}</vs-col>
            </vs-row>
            <!-- envoyé par -->
            <vs-row class="px-2 mt-3 w-full">
              <vs-col vs-type="flex" vs-justify="default" vs-w="6">{{ this.$t('sender') }} :</vs-col>
              <vs-col vs-type="flex" vs-justify="flex-end" vs-align="flex-end" vs-w="6">{{ `${first_name} ${last_name}` | capitalize }}</vs-col>
            </vs-row>
          </div>

          <!-- INFOS DE COMPORTEMENT 3D -->
          <h5 class="mt-6 mb-0 w-full">{{ this.$t('layerProperties') }}</h5><!-- Two columns -->
          <div class="px-2 mt-2 w-full">
            <!-- déplaceable -->
            <vs-row class="px-2 mt-3 w-full">
              <vs-col vs-type="flex" vs-justify="default" vs-w="6">{{ this.$t('layer_movable') }} :</vs-col>
              <vs-col vs-type="flex" vs-justify="flex-end" vs-align="flex-end" vs-w="6">
                <vs-switch @change="prepareForm" @blur="prepareForm" v-model="layer_new_movable" color="warning" />
              </vs-col>
            </vs-row>
            <!-- déformable -->
            <vs-row class="px-2 mt-3 w-full">
              <vs-col vs-type="flex" vs-justify="default" vs-w="6">{{ this.$t('layer_deformable') }} :</vs-col>
              <vs-col vs-type="flex" vs-justify="flex-end" vs-align="flex-end" vs-w="6">
                <vs-switch @change="prepareForm" @blur="prepareForm" v-model="layer_new_deformable" color="warning"/>
              </vs-col>
            </vs-row>
            <vs-row class="px-2 mt-3 w-full">
              <vs-alert color="warning">
                <template v-if="layer_new_movable">
                  <template v-if="layer_new_deformable">
                    {{ this.$t('layerMovableDeformable') }}
                  </template>
                  <template v-else>
                    {{ this.$t('layerMovableNotDeformable') }}
                  </template>
                </template>
                <template v-else>
                  <template v-if="layer_new_deformable">
                    {{ this.$t('layerNotMovableDeformable') }}
                  </template>
                  <template v-else>
                    {{ this.$t('layerNotMovableNotDeformable') }}
                  </template>
                </template>
              </vs-alert>
            </vs-row>
          </div>

          <!-- INFOS DE GEOPOSITIONNEMENT -->
          <template v-if="layer_type =='bim'">
            <h5 class="mt-6 mb-0 w-full">{{ this.$t('layerOriginInformation') }}</h5><!-- Two columns -->
            <div class="flex mt-2 layer_origin">
              <vs-input 
                @keydown.enter="prepareForm" 
                @blur="prepareForm" 
                :label="$t('layer_origin_x')"     
                v-model="layer_new_origin.X"      
                name="layer_origin_x"     
                class="layer_origin w-1/3 m-0"  
                :disabled="true"/>
              <vs-input 
                @keydown.enter="prepareForm" 
                @blur="prepareForm" 
                :label="$t('layer_origin_y')"     
                v-model="layer_new_origin.Y"      
                name="layer_origin_y"     
                class="layer_origin w-1/3 m-0"  
                :disabled="true"/>
              <vs-input 
                @keydown.enter="prepareForm" 
                @blur="prepareForm" 
                :label="$t('layer_origin_z')"     
                v-model="layer_new_origin.Z"      
                name="layer_origin layer_origin_z"     
                class="w-1/3 m-0"  
                :disabled="true"/>
            </div>
            <div class="flex mt-2 w-full">
              <vs-button class="mr-6 w-full flex-end" size="small" type="line"
                @click="resetLayerOrigin">{{this.$t('resetOrigin')}}
              </vs-button>
            </div>
          </template>

          <!-- INFOS D'ALLOCATIONS -->
          <h5 class="mt-10 w-full">{{ this.$t('projectsAllocations') }}</h5><!-- Two columns -->
            <!-- liste les projets utilisant le layer -->
            <template v-if="layerProjectsLoaded">
              <vs-alert color="warning" v-if="layerProjects.length === 0" active="true">
                {{ this.$t('noAllocation') }}
              </vs-alert>
              <vs-row vs-type="flex" vs-justify="space-between" v-else :key="project.project_id" v-for="project in layerProjects" class="px-2 mt-3 w-full">
                <vs-col vs-type="flex" vs-justify="center" vs-align="center">
                    <vs-avatar size="35px" :src="`${imgBaseUrl}/project/cover/${project.project_id}/${thumbName}`" @click="gotoProject(project.project_slug)" />
                    <span class="pl-2 truncated_title">{{ decodeURIComponent(project.project_nicename) }}</span>
                    <vs-button color="danger" type="line" icon-pack="feather" icon="icon-trash" @click="removeProjectLayerAllocation(`${project.project_id}`)"></vs-button>
                </vs-col>
              </vs-row>
            </template>
        </template>
      </div>
    </component>

    <!-- enregistrer ou annuler -->
    <div class="flex flex-wrap items-center p-6" slot="footer">
      <vs-button class="mr-6" @click="editLayerSave" :disabled="btn_disabled">{{this.$t('Save')}}</vs-button>
      <vs-button type="border" @click="editLayerCancel" color="danger">{{this.$t('Cancel')}}</vs-button>
    </div>

    <!-- suppression du layer -->
    <div class="flex flex-wrap items-center p-6" slot="footer">
      <template v-if="allocations_count > 0">
        <vs-alert active="true">
          {{ this.$t('cannotDeleteAllocatedLayer') }}. {{ this.$t('removeLayersAllocations') }}.
        </vs-alert>
      </template>
      <vs-button v-else :disabled="!allocations_count > 0" @click="deleteLayerConfirm" color="danger" icon-pack="feather" icon="icon-trash">{{this.$t('deleteLayer')}}</vs-button>
    </div>

  </vs-sidebar>
</template>

<script>
import axios from '@/axios.js'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import logActivity from '@/logActivity.js'
import CryptoJS from 'crypto-js'
import sha1 from 'crypto-js/sha1'
import md5 from 'crypto-js/md5'

export default {
  props: {
    isSidebarActive: {
      type: Boolean,
      required: true
    },
    data: {
      type: Object,
      default: () => {}
    }
  },
  components: {
    VuePerfectScrollbar
  },
  data () {
    return {
      // ROYBON / API calls
      api_server_baseurl: this.$appConfig.apiBaseUrl,
      imgBaseUrl: this.$appConfig.imgBaseUrl,
      getPortalLayersProjects: 'getPortalLayersProjects',
      updateLayerName: 'updateLayerName',
      getLayerByLayerKey: 'getLayerByLayerKey',
      updateLayerOrigin: 'updateLayerOrigin',
      updateLayerProperties: 'updateLayerProperties',
      deletePortalLayerAllocation: 'deletePortalLayerAllocation',
      getUserProjectCaps: 'getUserProjectCaps',
      deleteProjectLayerAllocation: 'deleteProjectLayerAllocation',

      // ROYBON / Liste des projets utilisant la couche
      layerProjects: {},
      layerProjectsLoaded: false,

      // ROYBON / Image du projet
      thumbName: 'thumb_200_200.jpg',

      // ROYBON / Layer informations
      allocations_count: '',
      biggest_layer_percentage: '',
      conversion_end_date: '',
      conversion_start_date: '',
      conversion_status: '',
      conversion_step: '',
      data_title: '',
      data_type: '',
      first_name: '',
      for_portals: '',
      last_name: '',
      layer_id: '',
      layer_key: '',
      layer_name: '',
      layer_server: '',
      layer_size: '',
      layer_type: '',
      portal: '',
      portal_space_percentage: '',
      server_id: '',
      upload_date: '',
      uploaded_filename: '',
      user_email: '',
      user_id: '',

      // ROYBON / Changements
      btn_disabled: true,

      // ROYBON / Changement de titre
      layer_new_name: '',
      layerTitleModified: false,

      // ROYBON / Changements de propriétés
      layer_movable: false,
      layer_deformable: false,
      layer_new_movable: false,
      layer_new_deformable : false,
      layerPropertiesModified: false,

      // ROYBON / Changement d'origine
      layer_new_origin: {
        X: null,
        Y: null,
        Z: null
      },
      layerOriginModified: false,

      // ROYBON / id du project dans lequel le layer doit être dé-alloué
      projectWhereToRemoveLayer: '',

      // ROYBON / Display
      settings: { // perfectscrollbar settings
        maxScrollbarLength: 60,
        wheelSpeed: .60
      }
    }
  },

  watch: {
    layer_new_name: function () {
    },
    layer_id: function () {
      this.getLayerProjects()
      logActivity.add('layer_sidebar_open', 'success', null, null, null, this.layer_id)
    },
    layerProjects: function () {
      this.layerProjectsLoaded = true
    },
    isSidebarActive (val) {
      if (!val) return
      if (Object.entries(this.data).length === 0) {
        this.initValues()
        this.$validator.reset()
      } else {
        const { allocations_count          } = JSON.parse(JSON.stringify(this.data))
        const { biggest_layer_percentage   } = JSON.parse(JSON.stringify(this.data))
        const { conversion_end_date        } = JSON.parse(JSON.stringify(this.data))
        const { conversion_start_date      } = JSON.parse(JSON.stringify(this.data))
        const { conversion_status          } = JSON.parse(JSON.stringify(this.data))
        const { conversion_step            } = JSON.parse(JSON.stringify(this.data))
        const { data_title                 } = JSON.parse(JSON.stringify(this.data))
        const { data_type                  } = JSON.parse(JSON.stringify(this.data))
        const { first_name                 } = JSON.parse(JSON.stringify(this.data))
        const { for_portals                } = JSON.parse(JSON.stringify(this.data))
        const { last_name                  } = JSON.parse(JSON.stringify(this.data))
        const { layer_id                   } = JSON.parse(JSON.stringify(this.data))
        const { layer_key                  } = JSON.parse(JSON.stringify(this.data))
        const { layer_name                 } = JSON.parse(JSON.stringify(this.data))
        const { layer_server               } = JSON.parse(JSON.stringify(this.data))
        const { layer_size                 } = JSON.parse(JSON.stringify(this.data))
        const { layer_movable              } = JSON.parse(JSON.stringify(this.data))
        const { layer_deformable           } = JSON.parse(JSON.stringify(this.data))
        const { layer_type                 } = JSON.parse(JSON.stringify(this.data))
        const { layer_origin               } = JSON.parse(JSON.stringify(this.data))
        const { portal                     } = JSON.parse(JSON.stringify(this.data))
        const { portal_space_percentage    } = JSON.parse(JSON.stringify(this.data))
        const { server_id                  } = JSON.parse(JSON.stringify(this.data))
        const { upload_date                } = JSON.parse(JSON.stringify(this.data))
        const { uploaded_filename          } = JSON.parse(JSON.stringify(this.data))
        const { user_email                 } = JSON.parse(JSON.stringify(this.data))
        const { user_id                    } = JSON.parse(JSON.stringify(this.data))

        this.layer_name                     = decodeURIComponent(layer_name)
        this.allocations_count              = allocations_count
        this.biggest_layer_percentage       = biggest_layer_percentage
        this.conversion_end_date            = conversion_end_date
        this.conversion_start_date          = conversion_start_date
        this.conversion_status              = conversion_status
        this.conversion_step                = conversion_step
        this.data_title                     = data_title
        this.data_type                      = data_type
        this.first_name                     = first_name
        this.for_portals                    = for_portals
        this.last_name                      = last_name
        this.layer_id                       = layer_id
        this.layer_key                      = layer_key
        this.layer_name                     = layer_name
        this.layer_server                   = layer_server
        this.layer_size                     = layer_size
        this.layer_movable                  = layer_movable == 1 || layer_movable == '1' || layer_movable == 'true'
        this.layer_deformable               = layer_deformable == 1 || layer_deformable == '1' || layer_deformable == 'true'
        this.layer_type                     = layer_type
        this.portal                         = portal
        this.portal_space_percentage        = portal_space_percentage
        this.server_id                      = server_id
        this.upload_date                    = upload_date
        this.uploaded_filename              = uploaded_filename
        this.user_email                     = user_email
        this.user_id                        = user_id

        this.layer_new_name = decodeURIComponent(this.layer_name)
        this.layer_new_movable = this.layer_movable
        this.layer_new_deformable = this.layer_deformable

        if (layer_origin && layer_origin.includes('{')) {
          this.layer_origin = JSON.parse(layer_origin.replaceAll('\'', '"').replaceAll('\'', ''))
          if (typeof layer_origin.X != 'undefined') {
            this.layer_origin.X = parseFloat(this.layer_origin.X)
            this.layer_origin.Y = parseFloat(this.layer_origin.Y)
            this.layer_origin.Z = parseFloat(this.layer_origin.Z)
          }
        } else {
          this.layer_origin = {
            X: 0,
            Y: 0,
            Z: 0
          }
        }
        this.layer_new_origin = JSON.parse(JSON.stringify(this.layer_origin))        
      }
      // Object.entries(this.data).length === 0 ? this.initValues() : { this.dataId, this.layer_name, this.dataCategory, this.dataOrder_status, this.dataPrice } = JSON.parse(JSON.stringify(this.data))
    }
  },
  computed: {
    
    isSidebarActiveLocal: {
      get () {
        return this.isSidebarActive
      },
      set (val) {
        if (!val) {
          this.$emit('closeSidebar')
        }
      }
    },
    scrollbarTag () { return this.$store.getters.scrollbarTag }
  },
  methods: {
    // ROYBON / Get origin coordinates from json file generated during conversion
    resetLayerOrigin () {
      // get layer url
      const params = `portal_id=${this.$store.state.portal_id}&layer_key=${this.layer_key}`
      const rimnat_api_call_url = `${this.api_server_baseurl}/layer/${this.getLayerByLayerKey}?${params}`
      console.log(rimnat_api_call_url)
      axios.get(rimnat_api_call_url, {})        
        .then((response) => { 
          console.log(response)
          if (response.data != null && response.data != 'null' && typeof response == 'object') {
            const layerUrl = response.data[0].layer_url
            const layerUrlProxy = layerUrl.replace(this.$appConfig.data01BaseUrl, this.$appConfig.data01BaseUrlProxy)
            const layerOriginJsonUrl = `${layerUrlProxy}/layer_origin.json`
            // read json file
            if (layerUrl && layerUrl != '') {
              axios.get(layerOriginJsonUrl, {})
                .then((response) => { 
                  console.log(response)
                  if (response && response.data != null && response.data != 'null') {
                    let layerBaseOrigin = typeof response.data == 'object' ? response.data : JSON.parse(response.data)
                    layerBaseOrigin = JSON.parse(JSON.stringify(layerBaseOrigin))
                    console.log(layerBaseOrigin)
                    this.layer_origin = {
                      X : parseFloat(layerBaseOrigin.X) || 0,
                      Y : parseFloat(layerBaseOrigin.Y) || 0,
                      Z : parseFloat(layerBaseOrigin.Z) || 0
                    }
                    this.layer_new_origin = JSON.parse(JSON.stringify(this.layer_origin))
                    this.prepareForm()
                  }
                })
                .catch((error)   => { console.log(error) })
            }
          }

        })
        .catch((error)   => { console.log(error) })


    },
    // ROYBON / Go to project
    gotoProject (project_slug) {
      this.isSidebarActiveLocal = false
      this.$router.push(`/project/${project_slug}`).catch(() => {})
    },
    // ROYBON / Préparation du formulaire
    prepareForm () {
      this.layer_new_name = this.sanitizeTitle(this.layer_new_name)
      this.layer_new_origin.X = this.sanitizeFloat(this.layer_new_origin.X)
      this.layer_new_origin.Y = this.sanitizeFloat(this.layer_new_origin.Y)
      this.layer_new_origin.Z = this.sanitizeFloat(this.layer_new_origin.Z)
      if (decodeURIComponent(this.layer_new_name) !== decodeURIComponent(this.layer_name)) {
        this.btn_disabled = false 
        this.layerTitleModified = true
      } else if (JSON.stringify(this.layer_new_origin) != JSON.stringify(this.layer_origin)) {
        this.btn_disabled = false 
        this.layerOriginModified = true
      } else if (this.layer_new_deformable !== this.layer_deformable || this.layer_new_movable !== this.layer_movable) {
        this.btn_disabled = false 
        this.layerPropertiesModified = true
      } else {
        this.btn_disabled = true 
      }
    },
    // ROYBON / Fonction de nettoyage du titre
    sanitizeTitle (value) {
      value = `${value}`
      const regex = /(<([^>]+)>)/ig
      value = value.replace(regex, '')
      value = value.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
      value = value.replace(/[^0-9a-z_-]/gi, '¤')
      value = value.replace(/ /g, '¤')
      value = value.split('¤¤').join('¤')
      value = value.substring(0, 30)
      value = value.replace(/¤/gi, ' ')
      value = value.trim()
      return value
    },
    // ROYBON / Fonction de nombres
    sanitizeFloat (value) {
      value = `${value}`
      value = value.replaceAll(',', '.')
      const regex = /[^0-9.]/g
      value = value.replace(regex, '')
      value = value.trim()
      value = parseFloat(value)
      return value
    },
    // ROYBON / Sauvegarde des modification
    editLayerSave () {
      // edit layer name
      if (this.layerTitleModified) {
        const urlEncodedValue = encodeURIComponent(this.layer_new_name)
        const params = new FormData()
        params.set('layer_id', `${this.layer_id}`)
        params.set('value', `${urlEncodedValue}`)
        const rimnat_api_call_url = `${this.api_server_baseurl}/layer/${this.updateLayerName}`
        axios.post(rimnat_api_call_url, params)
          .then((response) => { 
            if (response.data[0] === 'ok' || response.data == 'ok') {
              // success
              this.$vs.notify({
                color:'success',
                title:this.$t('UpdateConfirmed'),
                text:this.$t('ParameterUpdated')
              })
              this.layer_name = this.layer_new_name
              this.btn_disabled = true 
            } else {
              // error
              event.target.checked = !event.target.checked
              this.$vs.notify({
                color:'danger',
                title:this.$t('UpdateError'),
                text:this.$t('ParameterNotUpdated')
              })
              this.layer_new_name = this.layer_name
              this.btn_disabled = false 
            }
            logActivity.add('layer_update', response.data[0], null, null, null, this.layer_id)
          })
          .catch((error)   => { 
            console.log(error) 
            // error
            event.target.checked = !event.target.checked
            this.$vs.notify({
              color:'danger',
              title:this.$t('UpdateError'),
              text:this.$t('ParameterNotUpdated')
            })
            this.layer_new_name = this.layer_name
            this.btn_disabled = false 
          })
      }
      // edit layer properties
      if (this.layerPropertiesModified) {
        const params = new FormData()
        params.set('layer_id', `${this.layer_id}`)
        params.set('layer_movable', `${JSON.stringify(this.layer_new_movable)}`)
        params.set('layer_deformable', `${JSON.stringify(this.layer_new_deformable)}`)
        const rimnat_api_call_url = `${this.api_server_baseurl}/layer/${this.updateLayerProperties}`
        axios.post(rimnat_api_call_url, params)
          .then((response) => { 
            if (response.data[0] === 'ok' || response.data == 'ok') {
              // success
              this.$vs.notify({
                color:'success',
                title:this.$t('UpdateConfirmed'),
                text:this.$t('ParameterUpdated')
              })
              this.layer_movable = this.layer_new_movable
              this.layer_deformable = this.layer_new_deformable
              this.btn_disabled = true 
              this.$emit('forceListRefresh')
            } else {
              // error
              event.target.checked = !event.target.checked
              this.$vs.notify({
                color:'danger',
                title:this.$t('UpdateError'),
                text:this.$t('ParameterNotUpdated')
              })
              this.layer_new_movable = this.layer_movable
              this.layer_new_deformable = this.layer_deformable
              this.btn_disabled = false 
              this.$emit('forceListRefresh')
            }
            logActivity.add('layer_update', response.data[0], null, null, null, this.layer_id)
          })
          .catch((error)   => { 
            console.log(error) 
            // error
            event.target.checked = !event.target.checked
            this.$vs.notify({
              color:'danger',
              title:this.$t('UpdateError'),
              text:this.$t('ParameterNotUpdated')
            })
            this.layer_new_movable = this.layer_movable
            this.layer_new_deformable = this.layer_deformable
            this.btn_disabled = false 
            this.$emit('forceListRefresh')
          })
      }

      // edit layer origin
      if (this.layerOriginModified) {
        const params = new FormData()
        params.set('layer_id', `${this.layer_id}`)
        params.set('value', `${JSON.stringify(this.layer_new_origin)}`)
        const rimnat_api_call_url = `${this.api_server_baseurl}/layer/${this.updateLayerOrigin}`
        axios.post(rimnat_api_call_url, params)
          .then((response) => { 
            if (response.data[0] === 'ok' || response.data == 'ok') {
              // success
              this.$vs.notify({
                color:'success',
                title:this.$t('UpdateConfirmed'),
                text:this.$t('ParameterUpdated')
              })
              this.layer_name = this.layer_new_name
              this.btn_disabled = true 
              this.$emit('forceListRefresh')
            } else {
              // error
              event.target.checked = !event.target.checked
              this.$vs.notify({
                color:'danger',
                title:this.$t('UpdateError'),
                text:this.$t('ParameterNotUpdated')
              })
              this.layer_new_name = this.layer_name
              this.btn_disabled = false
              this.$emit('forceListRefresh') 
            }
            logActivity.add('layer_update', response.data[0], null, null, null, this.layer_id)
          })
          .catch((error)   => { 
            console.log(error) 
            // error
            event.target.checked = !event.target.checked
            this.$vs.notify({
              color:'danger',
              title:this.$t('UpdateError'),
              text:this.$t('ParameterNotUpdated')
            })
            this.layer_new_name = this.layer_name
            this.btn_disabled = false 
            this.$emit('forceListRefresh')
          })
      }
    },
    // ROYBON / Annulation de l'édition
    editLayerCancel () {
      this.isSidebarActiveLocal = false
      this.layer_new_name = this.layer_name
    },
    // ROYBON / Chargement de la liste des projets utilisant le layer
    getLayerProjects () {
      const params = `portal_id=${this.$store.state.portal_id}&layer_id=${this.layer_id}`
      const rimnat_api_call_url = `${this.api_server_baseurl}/portal/${this.getPortalLayersProjects}?${params}`
      axios.get(rimnat_api_call_url, {
      })
        .then((response) => { 
          if (response.data != null && response.data != 'null') this.layerProjects = response.data
        })
        .catch((error)   => { console.log(error) })
    },
    // ROYBON / Confirmation de suppression
    deleteLayerConfirm () {
      this.isSidebarActiveLocal = false
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: this.$t('Confirm'),
        text: `${this.layer_name  } : ${  this.$t('deleteLayerConfirmMessage')}`,
        acceptText: this.$t('Confirm'),
        cancelText: this.$t('Cancel'),
        accept: this.deleteLayerAction
      })
    },
    // ROYBON / Action de suppression
    deleteLayerAction () {
      //const params = `layer_id=${this.layer_id}&portal_id=${this.$store.state.portal_id}`
      const params = new FormData()
      params.set('layer_id', `${this.layer_id}`)
      params.set('portal_id', `${this.$store.state.portal_id}`)
      const rimnat_api_call_url = `${this.api_server_baseurl}/portal/${this.deletePortalLayerAllocation}`
      axios.post(rimnat_api_call_url, params)
        .then((response) => { 
          if (response.data[0] === 'ok' || response.data == 'ok') {
            // success
            this.$vs.notify({
              color:'success',
              title:this.$t('layerDeletionSuccess'),
              text:this.$t('deletedLayer')
            })
            this.$emit('closeSidebar')
          } else {
            // error
            event.target.checked = !event.target.checked
            this.$vs.notify({
              color:'danger',
              title:this.$t('layerDeletionError'),
              text:this.$t('notDeletedLayer')
            })
          }
          // log l'activité du user
          logActivity.add('delete_portal_layer', response.data[0], null, null, null, this.layer_id)
        })
        .catch((error)   => { 
          console.log(error) 
          // error
          event.target.checked = !event.target.checked
          this.$vs.notify({
            color:'danger',
            title:this.$t('layerDeletionError'),
            text:this.$t('notDeletedLayer')
          })
        })
    },


    // ROYBON / Retire l'allocation d'un layer à un projet
    removeProjectLayerAllocation (project_id) {
      // si user can_project_layers_manage
      const user_hash = md5(sha1(this.user_id.toString()).toString(CryptoJS.enc.sha1)).toString(CryptoJS.enc.md5)
      const params = `user_id=${this.$store.state.AppActiveUser.user_id}&user_hash=${user_hash}&project_id=${project_id}`
      const rimnat_api_call_url = `${this.api_server_baseurl}/user/${this.getUserProjectCaps}?${params}`
      axios.get(rimnat_api_call_url, {
      })
        .then((response) => { 
          if (response.data[0].can_project_layers_manage === '1') {
            // success
            this.projectWhereToRemoveLayer = project_id
            this.deleteProjectLayerConfirm()
          } else {
            // error
            event.target.checked = !event.target.checked
            this.$vs.notify({
              color:'danger',
              title:this.$t('UpdateError'),
              text:this.$t('cannotRemoveProjectLayerAllocation')
            })
            this.layer_new_name = this.layer_name
            this.btn_disabled = false 
          }
        })
        .catch((error)   => { 
          console.log(error) 
        })
        // demande confirmation
      // sinon erreur
    },
    // ROYBON / Confirmation de retrait du ayer du projet
    deleteProjectLayerConfirm () {
      this.isSidebarActiveLocal = false
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: this.$t('ConfirmRemoval'),
        text: this.$t('ProjectLayerRemovalMessage'),
        acceptText: this.$t('Confirm'),
        cancelText: this.$t('Cancel'),
        accept: this.deleteProjectLayerAction
      })
    },
    // ROYBON / Action de suppression
    deleteProjectLayerAction () {
      //const params = `layer_id=${this.layer_id}&project_id=${this.projectWhereToRemoveLayer}`
      const params = new FormData()
      params.set('layer_id', `${this.layer_id}`)
      params.set('project_id', `${this.projectWhereToRemoveLayer}`)
      const rimnat_api_call_url = `${this.api_server_baseurl}/project/${this.deleteProjectLayerAllocation}`
      axios.post(rimnat_api_call_url, params)
        .then((response) => { 
          if (response.data[0] === 'ok' || response.data == 'ok') {
            // success
            this.$vs.notify({
              color: 'success',
              title: this.$t('ConfirmedRemoval'),
              text: this.$t('ProjectLayerRemovalConfirmationMessage')
            })
          } else {
            // error
            event.target.checked = !event.target.checked
            this.$vs.notify({
              color:'danger',
              title:this.$t('FailedRemoval')
            })
          }
          // log l'activité du user
          logActivity.add('delete_project_layer', response.data[0], null, null, this.projectWhereToRemoveLayer, this.layer_id)
          
          this.layerProjectsLoaded = false
          this.getLayerProjects()
          this.$emit('closeSidebar')
        })
        .catch((error)   => { 
          console.log(error) 
          // error
          event.target.checked = !event.target.checked
          this.$vs.notify({
            color:'danger',
            title:this.$t('FailedRemoval')
          })
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.layer_origin {
  font-size: 0.75rem;
  font-family: monospace;
}
.add-new-data-sidebar {
  .truncated_title {
    width: 250px;
    // overflow: hidden;
    // white-space: nowrap;
    // text-overflow: ellipsis;
    // word-break: break-all;
    line-break: auto;
  }
  ::v-deep .vs-sidebar--background {
    z-index: 52010;
  }

  ::v-deep .vs-sidebar {
    z-index: 52010;
    width: 400px;
    max-width: 90vw;

    .img-upload {
      margin-top: 2rem;

      .con-img-upload {
        padding: 0;
      }

      .con-input-upload {
        width: 100%;
        margin: 0;
      }
    }
  }
}

.scroll-area--data-list-add-new {
    // height: calc(var(--vh, 1vh) * 100 - 4.3rem);
    height: calc(var(--vh, 1vh) * 100 - 16px - 45px - 82px);

    &:not(.ps) {
      overflow-y: auto;
    }
}

</style>