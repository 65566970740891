<template>
    <div id="layers-page" :key="'upload_zone_v'+uploadZoneVersion">
        <vs-alert v-if="!this.can_see_page" color="warning" :title="$t('AccessDeniedTitle')" active="true">
          <p>{{ $t(this.AccessDeniedMessage) }}<br>[{{ this.AccessDeniedInfos }}]</p>
        </vs-alert>

        <template v-else>
          <!-- SIDEBAR -->
          <data-view-sidebar :isSidebarActive="addNewDataSidebar" @closeSidebar="toggleDataSidebar" @forceListRefresh="forceLayersListRefresh" :data="sidebarData" />
          
          <vs-tabs ref="tabs" alignment="fixed">
            <!----------------------- MANAGER TAB --------------------->
            <vs-tab ref="managerTab" :label="$t('layersManager')" icon-pack="feather" icon="icon-layers">
              <div class="tab-text mt-8">

                <!-- QUOTAS -->
                <vx-card :title="this.$t('subscriptionUsage')" remove-card-action>
                  <subscription-quotas :actualisation="incrementedSubscriptionCheck" display disk layers/>
                </vx-card>
                
                <!-- ALIVE CONVERSIONS -->
                <div class="mt-10 ml-4">
                  <h3>{{this.$t('aliveConversions')}}</h3>
                </div>

                <div class="data-list-container">

                  <vs-alert v-if="freshUpload" class="m-6" icon-pack="feather" icon="icon-check" color="success">{{this.$t('uploadSuccess')}}</vs-alert>
                  <vs-alert v-if="this.activeAliveConversions === 0 && !freshUpload" class="m-6">{{this.$t('noAliveConversion')}}</vs-alert>
                  
                  <vs-table v-if="this.activeAliveConversions !== 0" ref="conversions_table" :data="this.aliveConversions">
                    <template slot="thead">
                      <vs-th sort-key="conversion_start_date">{{$t('conversion_start_date')}}</vs-th>
                      <vs-th sort-key="data_title">{{$t('data_title')}}</vs-th>
                      <vs-th sort-key="data_type">{{$t('data_type')}}</vs-th>
                      <vs-th sort-key="sender">{{$t('sender')}}</vs-th>
                      <vs-th sort-key="progression">
                        {{$t('progression')}}
                      </vs-th>
                    </template>
                    <template slot-scope="{data}">
                      <tbody>
                        <template v-for="(tr, indextr) in data">
                          <vs-tr :data="tr" :key="indextr" v-if="tr.conversion_status==='alive' || tr.conversion_status==='waiting' || tr.conversion_step==='0'">

                            <vs-td>
                              <p>{{ tr.conversion_start_date | longDate }}</p>
                            </vs-td>

                            <vs-td>
                              <p class="layer_name">{{ decodeURIComponent(tr.data_title) }}</p>
                            </vs-td>

                            <vs-td>
                              <p class="layer_type">{{ $t(tr.data_type) | title }}</p>
                            </vs-td>

                            <vs-td class="inline">
                              <ul class="flex flex-wrap items-center">
                                <li class="inline-flex items-end">
                                  <user-avatar :user="tr" size="small" :key="'avatar_'+tr.user_id" />
                                </li>
                                <li class="inline-flex items-end">
                                  {{tr.first_name+' '+tr.last_name | capitalize}}
                                </li>
                              </ul>
                            </vs-td>

                            <vs-td class="whitespace-no-wrap">
                              <ul class="flex flex-wrap items-center">
                                <span v-if="tr.conversion_status === 'waiting' || tr.conversion_step === '0'">{{$t('pendingConversion')}}</span>
                                <vs-progress v-else :percent="Number(tr.progression)*100" class="shadow-md" />
                              </ul>
                            </vs-td>

                          </vs-tr>
                        </template>
                      </tbody>
                    </template>
                  </vs-table>
                </div>

                <!-- FAILED CONVERSIONS -->
                <template v-if="failedConversions.length > 0">
                  <h3 class="mt-10 ml-4">{{this.$t('failedConversions')}}</h3>
                  <vs-table ref="failed_conversions_table" pagination :max-items="5" :data="this.failedConversions">
                    <template slot="thead">
                      <vs-th sort-key="conversion_start_date">{{$t('conversion_start_date')}}</vs-th>
                      <vs-th sort-key="data_title">{{$t('data_title')}}</vs-th>
                      <vs-th sort-key="data_type">{{$t('data_type')}}</vs-th>
                      <vs-th sort-key="sender">{{$t('sender')}}</vs-th>
                    </template>
                    <template slot-scope="{data}">
                      <tbody>
                        <template v-for="(tr, indextr) in data">
                          <vs-tr :data="tr" :key="indextr" v-if="tr.conversion_status==='error'">

                            <vs-td>
                              <p>{{ tr.conversion_start_date | longDate }}</p>
                            </vs-td>

                            <vs-td>
                              <p class="layer_name">{{ decodeURIComponent(tr.data_title) }}</p>
                            </vs-td>

                            <vs-td>
                              <p class="layer_type">{{ $t(tr.data_type) | title }}</p>
                            </vs-td>

                            <vs-td class="inline">
                              {{tr.first_name+' '+tr.last_name | capitalize}}
                            </vs-td>

                          </vs-tr>
                        </template>
                      </tbody>
                    </template>
                  </vs-table>
                </template>

                <!-- FINISHED CONVERSIONS -->
                <h3 class="mt-10 ml-4">{{this.$t('finishedConversions')}}</h3>
                <div id="data-list-list-view" class="data-list-container">
                  

                  <vs-table ref="layers_table" v-model="selected" pagination :max-items="itemsPerPage" search :data="this.portalLayers">
                    <div slot="header" class="flex flex-wrap-reverse items-center flex-grow justify-between">
                      <div class="flex flex-wrap-reverse items-center data-list-btn-container">
                      </div>

                      <!-- ITEMS PER PAGE -->
                      <vs-dropdown vs-trigger-click class="cursor-pointer mb-4 mr-4 items-per-page-handler">
                        <div class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium">
                          <span class="mr-2">{{ currentPage * itemsPerPage - (itemsPerPage - 1) }} - {{ portalLayers.length - currentPage * itemsPerPage > 0 ? currentPage * itemsPerPage : portalLayers.length }} of {{ queriedItems }}</span>
                          <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
                        </div>
                        <vs-dropdown-menu>
                          <vs-dropdown-item @click="itemsPerPage=5">
                            <span>5</span>
                          </vs-dropdown-item>
                          <vs-dropdown-item @click="itemsPerPage=10">
                            <span>10</span>
                          </vs-dropdown-item>
                          <vs-dropdown-item @click="itemsPerPage=25">
                            <span>25</span>
                          </vs-dropdown-item>
                          <vs-dropdown-item @click="itemsPerPage=50">
                            <span>50</span>
                          </vs-dropdown-item>
                          <vs-dropdown-item @click="itemsPerPage=100">
                            <span>100</span>
                          </vs-dropdown-item>
                        </vs-dropdown-menu>
                      </vs-dropdown>
                    </div>

                    <template slot="thead">
                      <vs-th sort-key="layer_id">{{$t('layer_id')}}</vs-th>
                      <vs-th sort-key="conversion_end_date">{{$t('conversion_end_date')}}</vs-th>
                      <vs-th sort-key="layer_name">{{$t('layer_name')}}</vs-th>
                      <vs-th sort-key="layer_type">{{$t('layer_type')}}</vs-th>
                      <vs-th sort-key="layer_origin">{{$t('layer_origin')}}</vs-th>
                      <vs-th sort-key="layer_movable">{{$t('layer_movable')}}</vs-th>
                      <vs-th sort-key="layer_deformable">{{$t('layer_deformable')}}</vs-th>
                      <vs-th sort-key="biggest_layer_percentage">{{$t('layer_size')}}</vs-th>
                      <vs-th sort-key="allocations_count">{{$t('allocations_count')}}</vs-th>
                      <vs-th>{{$t('Action')}}</vs-th>
                    </template>

                    <template slot-scope="{data}">
                      <tbody>
                        <template v-for="(tr, indextr) in data">
                          <vs-tr :data="tr" :key="indextr" v-if="tr.conversion_status !== 'alive' && tr.conversion_status !== 'pending'">

                            <vs-td><p class="layer_id font-medium truncate">{{ tr.layer_id }}</p></vs-td>

                            <vs-td>
                              <p>{{ tr.conversion_end_date | middleDate }}</p>
                            </vs-td>

                            <vs-td>
                              <p class="layer_name">{{ decodeURIComponent(tr.layer_name) }}</p>
                            </vs-td>

                            <vs-td>
                              <p class="layer_type">{{ $t(tr.layer_type) | title }}</p>
                            </vs-td>

                            <vs-td>
                              <template v-if="tr.layer_origin && tr.layer_origin != ''">
                                <p class="layer_origin">X: {{ getLayerOriginAsObject(tr.layer_origin).X }}</p>
                                <p class="layer_origin">Y: {{ getLayerOriginAsObject(tr.layer_origin).Y }}</p>
                                <p class="layer_origin">Z: {{ getLayerOriginAsObject(tr.layer_origin).Z }}</p>
                              </template>
                            </vs-td>

                            <vs-td class="center">
                              <feather-icon v-if="tr.layer_movable == 1" icon="CheckIcon" svgClasses="text-warning stroke-current"></feather-icon>
                              <feather-icon v-else icon="XIcon"></feather-icon>
                            </vs-td>

                            <vs-td class="center">
                              <feather-icon v-if="tr.layer_deformable == 1" icon="CheckIcon" svgClasses="text-warning stroke-current"></feather-icon>
                              <feather-icon v-else icon="XIcon"></feather-icon>
                            </vs-td>

                            <vs-td v-if="!isNaN(tr.layer_size)">
                              <p class="layer_size">{{ parseFloat(tr.layer_size) | prettyBytes }}</p>
                              <vs-progress :percent="Number(tr.biggest_layer_percentage)*100" :color="getSizeColor(Number(tr.biggest_layer_percentage*100))" class="shadow-md" />
                            </vs-td>

                            <vs-td>
                              <vs-chip :color="getAllocationsColor(tr.allocations_count)" class="layer_allocations_count">{{ tr.allocations_count }}</vs-chip>
                            </vs-td>

                            <vs-td class="whitespace-no-wrap text-right">
                              <feather-icon v-if="tr.layer_type == 'pointcloud' || tr.layer_type == 'mesh'" icon="EyeIcon" svgClasses="w-5 h-5 hover:text-primary stroke-current" @click.stop="previewData(tr)" />
                              <feather-icon icon="EditIcon" svgClasses="ml-2 w-5 h-5 hover:text-primary stroke-current" @click.stop="editData(tr)" />
                            </vs-td>

                          </vs-tr>
                        </template>
                      </tbody>
                    </template>
                  </vs-table>
                </div>
              </div>
            </vs-tab>

            <!----------------------- UPLOADER TAB --------------------->
            <vs-tab v-if="uploadTabVisible" :disabled="this.$store.state.subscriptionUsage.layersAvailable <= 0 || this.$store.state.subscriptionUsage.spaceUsedPercent > 99" ref="uploaderTab" :label="$t('layersUploader')" icon-pack="feather" icon="icon-upload">
              <div class="tab-upload mt-8">
      
                <!-- data type picker -->
                <vx-card v-show="!uploadInProgress" :title="$t('data_type')" class="mt-8"> 
                  <vs-row vs-align="flex-start" vs-type="flex" vs-justify="space-around" vs-w="12">
                    <vs-col vs-w="9" vs-sm="6" vs-xs="12">
                      <div class="px-4 w-full">
                        <ul class="centerx mt-8">
                          <li :key="'type_'+type.slug" v-for="type in this.$store.state.layer_types_v14" class="pl-5">
                            <template v-if="displayFeature(type.slug)">
                              <vs-radio v-model="uploadType" :vs-value="type">
                                <feather-icon v-if="isBetaFeature(type.slug)" icon="PackageIcon" svgClasses="stroke-current text-primary w-4 h-4" class="ml-2" />
                                {{$t(type.i18n_single)}}
                              </vs-radio>
                            </template>
                          </li>
                        </ul>
                      </div>
                    </vs-col>
                    <!-- format explainer -->
                    <vs-col vs-w="3" vs-sm="6" vs-xs="12">
                      <img v-if="typeof uploadType.i18n_uploadExplainations !== 'undefined'" class="explainer_image" :src="`${this.$appConfig.imgBaseUrl}/${uploadType.img}`">
                    </vs-col>
                  </vs-row>
                  <vs-row v-if="uploadType !== null" class="mx-2 block">
                    <div v-if="typeof uploadType.i18n_uploadExplainations !== 'undefined'" class="flex flex-1 mt-8">
                      <feather-icon icon="AlertTriangleIcon" svgClasses="h-6 w-6" />
                      <h5 class="mt-1 ml-2">{{$t('dataExplainations')}}</h5>
                    </div>
                    <p class="mt-4">{{$t(this.uploadType.i18n_uploadExplainations)}}</p>
                    <vs-alert v-if="this.uploadType.i18n_uploadExplainationsDetails != null">{{$t(this.uploadType.i18n_uploadExplainationsDetails)}}</vs-alert>
                    <vs-alert v-if="this.uploadType.i18n_uploadExplainationsAlert != null" color="warning" class="mt-2">{{$t(this.uploadType.i18n_uploadExplainationsAlert)}}</vs-alert>
                  </vs-row>
                </vx-card>
      
                <!-- data title picker -->
                <vx-card v-show="!uploadInProgress" :title="$t('data_title')" v-if="typeof this.uploadType.extensions !== 'undefined'" class="mt-8"> 
                  <vs-row vs-align="flex-start" vs-type="flex" vs-justify="space-around" vs-w="12">
                    <vs-col vs-w="12">
                      <p>{{$t('nameYourData')}}</p>
                      <vx-input-group class="mb-base">
                        <vs-input @keydown.enter="prepareForm" @blur="prepareForm" 
                          v-model="uploadTitle" 
                          :placeholder="$t('data_title')"
                          name="data_title"
                        />
                        <template slot="append">
                          <div class="append-text btn-addon">
                            <vs-button>{{$t('Ok')}}</vs-button>
                          </div>
                        </template>
                      </vx-input-group>
                    </vs-col>
                  </vs-row>
                </vx-card>
              </div>
            </vs-tab>
          </vs-tabs>

          <!-- upload zone -->
          <div class="tab-upload mt-8 pl-3 pr-2">
            <vx-card :title="$t('Upload')" v-if="typeof this.uploadType.extensions !== 'undefined' && this.uploadTitle !=='' && this.uploadTitleIsValid"
            title-color="#fff"
            content-color="#fff"
            card-background="linear-gradient(to right, #56ab2f, #a8e063)"> 
              <vs-row vs-align="center" vs-type="flex" vs-justify="center" vs-w="12" >
                <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="12">
                  <vs-upload
                    fileName="file[]" 
                    :action="uploadUrl" 
                    :text="$t('choseData')"
                    limit="1"
                    multiple="false"
                    :accept="uploadType.extensions.join()"
                    @on-success="dataUploadSuccess" 
                    @on-error="dataUploadError" 
                    @change="dataUploadStart"
                    class="black_text"
                  />
                </vs-col>
              </vs-row>
            </vx-card>
          </div>
          
        </template>

    </div>
</template>
<script>
import axios from '@/axios.js'
import DataViewSidebar from './LayerSidebar.vue'
import SubscriptionQuotas from './subscription/quotas.vue'
import moment from 'moment' // for date comparaison
import logActivity from '@/logActivity.js'
import CryptoJS from 'crypto-js'
import sha1 from 'crypto-js/sha1'
import md5 from 'crypto-js/md5'

export default {
  components: {
    DataViewSidebar, SubscriptionQuotas
  },
  data () {
    return {
      
      // ROYBON / API calls
      api_server_baseurl: this.$appConfig.apiBaseUrl,
      getPortalLayersWithConvertDetails: 'getPortalLayersWithConvertDetails',
      getPortalSubscriptionUsage: 'getPortalSubscriptionUsage',
      getPortalAliveConversions: 'getPortalAliveConversions',
      getPortalFailedConversions: 'getPortalFailedConversions',

      // ROYBON / Upload
      uploadType: {},
      uploadTitle: '',
      uploadTitleIsValid : false,
      freshUpload: false,
      uploadTabVisible: true,
      uploadZoneVersion : 1,
      uploadInProgress : false,

      // ROYBON / Subscription usage
      incrementedSubscriptionCheck:0,

      // ROYBON / Layers
      portalLayers: [],

      // ROYBON / Failed conversions
      failedConversions: [],

      // ROYBON / Alive conversions
      aliveConversions: [],
      timer: '',
      previousAliveConversions: 0,
      activeAliveConversions: 0,
      queueLoaded: false,

      // ROYBON / Data list
      selected: [],
      itemsPerPage: 10,
      isMounted: false,

      // Data Sidebar
      addNewDataSidebar: false,
      sidebarData: {},

      // ROYBON / Interprétation des droits
      can_see_page: null,
      AccessDeniedMessage: '',
      AccessDeniedInfos: ''
    }
  },
  methods: {    
    getLayerOriginAsObject (input) {
      let returned = null
      if (input && input != '') {
        returned = JSON.parse(input.replaceAll('\'', '"').replaceAll('\'', ''))
        returned = {
          X : parseFloat(returned.X) || 0,
          Y : parseFloat(returned.Y) || 0,
          Z : parseFloat(returned.Z) || 0
        }
      }
      return returned
    },

    displayFeature (dataType) {
      const isBetaFeature = this.isBetaFeature(dataType)
      if (isBetaFeature) {
        return this.$store.state.betaTester == true
      } else {
        return true
      }
    },
    isBetaFeature (dataType) {
      const format = Object.entries(this.$store.state.layer_types_v14)
        .map(item => item[1])
        .filter(item => item.slug == dataType)
      const isBetaFeature = format[0].beta
      return isBetaFeature
    },
    // ROYBON / Action d'upload
    dataUploadSuccess () {
      this.freshUpload = true
      this.uploadTitle = ''
      this.uploadZoneVersion = this.uploadZoneVersion + 1
      // this.$refs.managerTab.click()
      this.$vs.notify({
        color:'primary',
        text:this.$t('uploadSuccess'),
        time:4000
      })
      logActivity.add('data_uploaded', 'success')
      this.uploadInProgress = false
    },
    dataUploadError () {
      this.uploadInProgress = false
    },
    dataUploadStart () {
      logActivity.add('data_upload_start', 'success')
      this.uploadInProgress = true
    },
    // ROYBON / Préparation du formulaire
    prepareForm () {
      this.uploadTitle = this.sanitizeTitle(this.uploadTitle)
      if (this.uploadTitle !== '') {
        this.uploadTitleIsValid = true
      }
    },
    // ROYBON / Fonction de nettoyage du titre
    sanitizeTitle (value) {
      const regex = /(<([^>]+)>)/ig
      value = value.replace(regex, '')
      value = value.trim()
      return value
    },
    editData (data) {
      // this.sidebarData = JSON.parse(JSON.stringify(this.blankData))
      this.sidebarData = data
      this.toggleDataSidebar(true)
    },
    forceLayersListRefresh () {
      this.portalLayers = []
      this.buildLayersList()
    },
    viewerUrl () {
      // get default config
      let viewerBaseUrl = this.$appConfig.viewerBaseUrl
      const viewerVersion = this.$store.state.viewerVersion || 'viewer'
      // modify url with viewerVersion stored in VueX
      if (viewerVersion != null && viewerVersion != '') {
        viewerBaseUrl = viewerBaseUrl.replace('viewer', viewerVersion)
      }
      return viewerBaseUrl
    },
    previewData (data) {
      const viewerUrl = this.viewerUrl()
      const viewerModelUrl = `${viewerUrl}/?portal_id=${this.$store.state.portal_id}&layer_key=${data.layer_key}`
      window.open(viewerModelUrl, 'preview_tab')
    },
    getSizeColor (num) {
      if (num > 80)  return 'danger'
      if (num > 60)  return 'warning'
      if (num >= 40) return 'primary'
      if (num < 40)  return 'success'
      return 'primary'
    },
    getAllocationsColor (num) {
      if (num === '0')  return 'danger'
      if (num === '1')  return 'primary'
      if (num > 1)  return 'success'
      return 'primary'
    },
    toggleDataSidebar (val = false) {
      this.addNewDataSidebar = val
      this.buildLayersList()
      if (val === false) this.refreshSubscriptionQuotas()
    },

    // ROYBON / Force l'actualisation des quotas de l'abonnement
    refreshSubscriptionQuotas () {
      this.incrementedSubscriptionCheck = this.incrementedSubscriptionCheck + 1
    },

    // ROYBON / Liste les portals ayant accès à ce project
    buildLayersList () {
      const params = `portal_id=${this.$store.state.portal_id}`
      const rimnat_api_call_url = `${this.api_server_baseurl}/portal/${this.getPortalLayersWithConvertDetails}?${params}`
      axios.get(rimnat_api_call_url, {
      })
        .then((response) => { 
          if (response.data != null && response.data != 'null') this.portalLayers = response.data
        })
        .catch((error)   => { console.log(error) })
    },

    // ROYBON / Liste les conversions en cours
    buildAliveConversionsList () {
      const params = `portal_id=${this.$store.state.portal_id}`
      const rimnat_api_call_url = `${this.api_server_baseurl}/portal/${this.getPortalAliveConversions}?${params}`
      axios.get(rimnat_api_call_url, {
      })
        .then((response) => { 
          if (response.data != null && response.data != 'null') this.aliveConversions = response.data
          this.activeAliveConversions = parseInt(response.data.length)
        })
        .catch((error)   => { console.log(error) })
    },

    // ROYBON / Liste les conversions échouée
    buildFailedConversionsList () {
      const params = `portal_id=${this.$store.state.portal_id}`
      const rimnat_api_call_url = `${this.api_server_baseurl}/portal/${this.getPortalFailedConversions}?${params}`
      axios.get(rimnat_api_call_url, {
      })
        .then((response) => { 
          if (response.data != null && response.data != 'null') this.failedConversions = response.data
        })
        .catch((error)   => { console.log(error) })
    },

    // ROYBON / Check si l'affichage de la page est autorisé
    canSeePage () {
      const gc =                this.$store.state.current_user_global_caps
      const poc =               this.$store.state.current_user_portal_caps
      const poc_start_date =    poc.can_portal_access_start_date
      const poc_end_date =      poc.can_portal_access_end_date
      if (gc.can_global_access === '1' && poc.can_portal_access === '1') {
        if (poc_start_date !== null && moment().isSameOrBefore(poc_start_date)) {
          this.can_see_page = false
          this.AccessDeniedMessage = 'PortalAccessNotYetOpened'
          this.AccessDeniedInfos = poc_start_date
        } else if (poc_end_date !== null && moment().subtract(1, 'd').isSameOrAfter(poc_end_date)) {
          this.can_see_page = false
          this.AccessDeniedMessage = 'PortalAccessExpired'
          this.AccessDeniedInfos = poc_end_date
        } else if (poc.can_portal_layers_manage !== '1') {
          this.can_see_page = false
          this.AccessDeniedMessage = 'cannotManageLayers'
        } else {
          this.can_see_page = true
        }
      } else {
        this.can_see_page = false
        this.AccessDeniedMessage = 'PortalAccessDeniedFull' 
        this.AccessDeniedInfos = 'global or portal access invalid'
      }
    }
  },
  beforeDestroy () {
    clearInterval(this.timer)
  },
  computed: {
    uploadUrl () {
      let url = ''
      const timestamp = Date.now()
      const timestampHash = md5(sha1(timestamp.toString()).toString(CryptoJS.enc.sha1)).toString(CryptoJS.enc.md5)
      if (typeof this.uploadType.slug !== 'undefined' && this.uploadTitle !== 'undefined' && this.uploadTitle !== '') {
        url = `${this.$appConfig.data01BaseUrl}/data/api/upload2.php?portal_id=${this.$store.state.portal_id}&data_title=${encodeURIComponent(this.uploadTitle)}&data_type=${this.uploadType.slug}&user_id=${this.$store.state.AppActiveUser.user_id}&user_email=${encodeURIComponent(this.$store.state.AppActiveUser.user_email)}`
        url = `${url}&timestamp=${timestamp}`
        url = `${url}&timestampHash=${timestampHash}`
        url = `${url}&encTk=${this.$store.state.AppActiveUser.encTk}`
      }
      return url
    },

    currentPage () {
      if (this.isMounted && typeof this.$refs.layers_table !== 'undefined') {
        return this.$refs.layers_table.currentx
      }
      return 0
    },
    queriedItems () {
      return this.$refs.table ? this.$refs.table.queriedResults.length : this.portalLayers.length
    }
  },

  beforeMount () {
    this.canSeePage()
  },

  created () {
    this.buildLayersList()
    this.timer = setInterval(this.buildLayersList, 30000)
    this.buildAliveConversionsList()
    this.buildFailedConversionsList()
    this.timer = setInterval(this.buildAliveConversionsList, 10000)
    this.timer = setInterval(this.buildFailedConversionsList, 10000)
  },
  mounted () {
    this.isMounted = true
  },

  watch: {
    can_see_page: function () {
      if (this.can_see_page) {
        logActivity.add('layer_manager_access', 'success')
      } else {
        logActivity.add('layer_manager_access_refused', 'error')
      }
    },
    freshUpload: function () {
      this.uploadTabVisible = false
    },
    aliveConversions: function () {
      if (this.queueLoaded) {
        const delta = parseInt(this.activeAliveConversions) - parseInt(this.previousAliveConversions) 
        // new job
        if (delta > 0) {
          this.$vs.notify({
            color:'primary',
            text:`${Math.abs(delta)} ${this.$t('newJobAdded')}`,
            time:4000
          })
          this.freshUpload = false

          // this.$refs.firstTab.click()
        } 
        // job finished
        if (delta < 0) {
          this.$vs.notify({
            text:`${Math.abs(delta)} ${this.$t('jobFinished')}`,
            color:'success',
            iconPack: 'feather',
            icon:'icon-check',
            time:4000
          })
        }
        // update layers list and quotas
        if (delta !== 0) {
          this.buildLayersList()
          this.refreshSubscriptionQuotas()
        }
      }
      this.previousAliveConversions = this.activeAliveConversions
      this.queueLoaded = true
    }
  }
}

</script>
<style lang="scss">
.center{
    text-align: center;
}
.layer_origin {
  font-size: 0.75rem;
  font-family: monospace;
}
.fresh_tr{
  font-style: italic;
  text-align: center;
  background: rgba(var(--vs-success),0.1);
  border: 1px solid rgba(0, 0, 0, 1);
}
.black_text{
  color:#000;
}
.con-img-upload .img-upload .btn-upload-file.on-progress {
  background: rgba(var(--vs-success),0.7);
}
.con-img-upload {
  background: unset;
  padding: 0;
}

.explainer_image {
  max-width: 100%;
  float: right;
  padding:20px;
}
.tab-upload {
  .con-input-upload {
    text-align: center;
    width: calc(100% - 60px);
  }
  .img-upload {
    text-align: center;
    margin: 15px;
    width: calc(100% - 60px);
  }
}
.data-list-container {
  .vs-con-table {

    /*
      Below media-queries is fix for responsiveness of action buttons
      Note: If you change action buttons or layout of this page, Please remove below style
    */
    @media (max-width: 689px) {
      .vs-table--search {
        margin-left: 0;
        max-width: unset;
        width: 100%;

        .vs-table--search-input {
          width: 100%;
        }
      }
    }

    @media (max-width: 461px) {
      .items-per-page-handler {
        display: none;
      }
    }

    @media (max-width: 341px) {
      .data-list-btn-container {
        width: 100%;

        .dd-actions,
        .btn-add-new {
          width: 100%;
          margin-right: 0 !important;
        }
      }
    }

    .layer_id {
      max-width: 23rem;
    }

    .inline {
      text-align: left;
      display: inline;
    }

    .vs-table--header {
      display: flex;
      flex-wrap: wrap;
      margin-left: 1.5rem;
      margin-right: 1.5rem;
      > span {
        display: flex;
        flex-grow: 1;
      }

      .vs-table--search{
        padding-top: 0;

        .vs-table--search-input {
          padding: 0.9rem 2.5rem;
          font-size: 1rem;

          &+i {
            left: 1rem;
          }

          &:focus+i {
            left: 1rem;
          }
        }
      }
    }

    .vs-table {
      border-collapse: separate;
      border-spacing: 0 1.3rem;
      padding: 0 1rem;

      tr{
          box-shadow: 0 4px 20px 0 rgba(0,0,0,.05);
          td{
            overflow-wrap: anywhere;
            padding: 20px;
            &:first-child{
              border-top-left-radius: .5rem;
              border-bottom-left-radius: .5rem;
            }
            &:last-child{
              border-top-right-radius: .5rem;
              border-bottom-right-radius: .5rem;
            }
          }
          td.td-check{
            padding: 20px !important;
          }
      }
    }

    .vs-table--thead{
      th {
        padding-top: 0;
        padding-bottom: 0;

        .vs-table-text{
          text-transform: uppercase;
          font-weight: 600;
        }
      }
      th.td-check{
        padding: 0 15px !important;
      }
      tr{
        background: none;
        box-shadow: none;
      }
    }

    .vs-table--pagination {
      justify-content: center;
    }
  }
}
</style>
